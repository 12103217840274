<script>
export default {
  name: "meta-title",
  props: ["title"],
  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = this.$t("pages.main_header_title") + this.title;
      }
    }
  },
  render() {
    return "";
  }
};
</script>
