const initialState = () => ({
	partners: [],
  });

  export const mutations = {
	SET_ENTITY(state, [key, payload]) {
	  state[key] = payload;
	},
	UPDATE_ENTITY(state, [key, payload, payloadKey]) {
	  const findIndex = state[key].findIndex(item => {
		return item[payloadKey] === payload[payloadKey];
	  });
	  if (findIndex) {
		state[key][findIndex] = payload;
	  }
	},
	DELETE_ENTITY(state, [key, payload, payloadKey]) {
	  const findIndex = state[key].findIndex(item => {
		return item[payloadKey] === payload[payloadKey];
	  });
	  if (findIndex !== -1) {
		state[key].splice(findIndex, 1);
	  }
	},
	RESET_STATE(state) {
	  const initial = initialState();
	  Object.keys(initial).forEach(key => {
		state[key] = initial[key];
	  });
	}
  };

  export const actions = {
    async index({ commit }) {
		const { data } = await this._vm.axios.get("/api/microsites");
	  commit("SET_ENTITY", ["partners", data]);
	}
  };

  export default {
	namespaced: true,
	state: initialState,
	mutations,
	actions
  };
