import router from "@/router";

const initialState = () => ({
  flight: {},
  flightLoading: "empty",
  flightDetails: {},
  flightDetailsLoading: "empty",
  serverNameLoading: "empty",
  crewExist: false,
  isFlight: false,
  interval: false,
  intervalTariff: false,
  isUpgrade: false,
  isFoodMenu: false,
  isService: false,
  serverName: {},
  planeType: "",
  planeAirSeats: [],
  planeLoading: "empty",
  reload: false,
  serverTime: new Date(),
  isShopOn: false
});

export const mutations = {
  SET_ENTITY(state, [key, payload]) {
    state[key] = payload;
  },
  ADD_ENTITY(state, [key, payload, position = "start"]) {
    if (position === "end") {
      state[key].push(payload);
    } else {
      state[key].unshift(payload);
    }
  },
  UPDATE_ENTITY(state, [key, payload, payloadKey]) {
    const findIndex = state[key].findIndex(item => {
      return item[payloadKey] === payload[payloadKey];
    });
    if (findIndex) {
      state[key][findIndex] = payload;
    }
  },
  UPDATE_PLANE_BY_INDEX(state, [payload, index]) {
    state.planeAirSeats[index] = {
      ...state.planeAirSeats[index],
      ...payload
    };
    state.reload = true;
    setTimeout(() => {
      state.reload = false;
    }, 1000);
  },
  DELETE_ENTITY(state, [key, payload, payloadKey]) {
    const findIndex = state[key].findIndex(item => {
      return item[payloadKey] === payload[payloadKey];
    });
    if (findIndex !== -1) {
      state[key].splice(findIndex, 1);
    }
  },
  RESET_STATE(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  }
};

export const getters = {
  SORT_POSITION: state => {
    if (state.flight.crewEmployers) {
      state.flight.crewEmployers.map(
        crew => (crew.order = getOrder(crew.position))
      );
    }
    return state.flight;
  }
};

export const getOrder = position => {
  const positions = [
    { position: "PIC", title: "Командир воздушного судна", order: 1 },
    { position: "CO", title: "Второй пилот", order: 2 },
    { position: "PI", title: "Пилот инструктор", order: 3 },
    { position: "FO", title: "Второй пилот", order: 3 },
    { position: "CCC", title: "Старший бортпроводник", order: 4 },
    { position: "CC", title: "Бортпроводник", order: 5 },
    { position: "CI", title: "Бортпроводник – инструктор", order: 6 }
  ];
  const res = positions.find(p => p.position === position);
  if (res) {
    return res.order;
  } else {
    return -1;
  }
};

export const actions = {

  async checkShopStatus({ commit }) {
    try {
      const { data } = await this._vm.axios.get("/api/pos/is-available", {
        headers: { Authorization: "3a156a2e-06f2-4512-81a5-4b43450305f4" }
      });
      await commit("SET_ENTITY", ["isShopOn", data.isAvailable]);
    } catch (e) {
      await commit("SET_ENTITY", ["isShopOn", false]);

    }
  },

  async checkFlightUpdate({ state, dispatch, rootState,commit }) {
    // dispatch("plane");
    try {
      const { data } = await this._vm.axios.get("/api/settings");

      if (
        data.system.domain.setId !=
        rootState.settings.index.system.domain.setId ||
        rootState.settings.index.system.domain.setVersion !=
        data.system.domain.setVersion
      ) {
        dispatch("STATE_CLEAR", {}, { root: true });
        dispatch("settings/index", {}, { root: true });
        await router.push("/");
      }
    } catch (e) {
      if (rootState.user.authenticate === false && state.isFlight === false) {
        dispatch("STATE_CLEAR", {}, { root: true });
        dispatch("settings/index", {}, { root: true });
        await router.push("/");
      }
    }

    try {
      let response = await this._vm.axios.get("/api/board/currentFlight");
      dispatch("services");
      if (
        Object.values(state.flight).length === 0 ||
        (state.flight.flightDate &&
          state.flight.flightNumber &&
          !(
            response.data.flightDate === state.flight.flightDate &&
            response.data.flightNumber === state.flight.flightNumber
          ))
      ) {
        dispatch("STATE_CLEAR", {}, { root: true });
        dispatch("settings/index", {}, { root: true });
        dispatch("flight");
        await router.push("/");
      }
    } catch (e) {
      commit("SET_ENTITY", ["isFoodMenu", false]);
      if (
        Object.values(state.flight).length &&
        rootState.user.authenticate === false
      ) {
        dispatch("STATE_CLEAR", {}, { root: true });
        dispatch("settings/index", {}, { root: true });
        dispatch("flight");
        await router.push("/");
      }
    }
  },
  async foodMenu({ commit, rootState }) {
    const {
      data: { menu }
    } = await this._vm.axios.get("/api/foodMenu");

    if (
      rootState.user.authenticate &&
      menu &&
      menu.food &&
      menu.food.find(
        it =>
          it.class ===
          (rootState.user.user.class === "c" ||
            rootState.user.user.class === "ss"
            ? "business"
            : "economy")
      )
    ) {
      commit("SET_ENTITY", ["isFoodMenu", true]);
    } else {
      commit("SET_ENTITY", ["isFoodMenu", false]);
    }
  },
  async services({ commit }) {
    const { data } = await this._vm.axios.get(
      "/api/board/statusServiceOnBoard"
    );
    if (data.isAvailabilityService) {
      commit("SET_ENTITY", ["isService", true]);
    } else {
      commit("SET_ENTITY", ["isService", false]);
    }
  },
  async flight({ commit, state, dispatch, rootState }) {
    commit("SET_ENTITY", ["flightLoading", true]);
    try {
      let response = await this._vm.axios.get("/api/board/currentFlight");
      commit("SET_ENTITY", ["flight", response.data]);
      dispatch("order/setOrderAfterSetFlight", {}, { root: true });
      if (state.interval) {
        clearInterval(state.interval);
      }

      if (state.flightDetailsLoading === "empty") {
        dispatch("flightDetails");
      }
      commit("settings/SET_ENTITY", ["preload", false], { root: true });
      commit("user/SET_ENTITY", ["skipCaptive", "empty"], { root: true });
      commit("SET_ENTITY", ["isFlight", true]);
      if (
        !Array.isArray(response.data.crewEmployers) ||
        response.data.crewEmployers.length === 0
      ) {
        commit("SET_ENTITY", ["crewExist", false]);
      } else {
        commit("SET_ENTITY", ["crewExist", true]);
      }
      commit("SET_ENTITY", ["interval", false]);
    } catch (e) {
      if (state.interval === false) {
        const intr = setInterval(() => {
          dispatch("flight");

          if (state.flightDetails === false) {
            dispatch("flightDetails");
          }
          if (Object.keys(rootState.settings.index) === 0) {
            dispatch("settings/index", {}, { root: true });
          }
          if (Object.keys(state.serverName) === 0) {
            dispatch("serverName");
          }
        }, 5000);
        commit("SET_ENTITY", ["interval", intr]);
      }
      commit("SET_ENTITY", ["isFlight", false]);
      commit("SET_ENTITY", ["crewExist", false]);
    }

    commit("SET_ENTITY", ["flightLoading", false]);
  },
  async flightCrew({ commit, state, dispatch, rootState }) {
    commit("SET_ENTITY", ["flightLoading", true]);
    try {
      let response = await this._vm.axios.get("/api/board/currentFlight");
      commit("SET_ENTITY", ["flight", response.data]);
      dispatch("order/setOrderAfterSetFlight", {}, { root: true });
      if (state.interval) {
        clearInterval(state.interval);
      }

      if (state.flightDetailsLoading === "empty") {
        dispatch("flightDetails");
      }
      commit("SET_ENTITY", ["isFlight", true]);
      if (
        !Array.isArray(response.data.crewEmployers) ||
        response.data.crewEmployers.length === 0
      ) {
        commit("SET_ENTITY", ["crewExist", false]);
      } else {
        commit("SET_ENTITY", ["crewExist", true]);
      }
      commit("SET_ENTITY", ["interval", false]);
    } catch (e) {
      if (state.interval === false) {
        const intr = setInterval(() => {
          dispatch("flight");

          if (state.flightDetails === false) {
            dispatch("flightDetails");
          }
          if (Object.keys(rootState.settings.index) === 0) {
            dispatch("settings/index", {}, { root: true });
          }
          if (Object.keys(state.serverName) === 0) {
            dispatch("serverName");
          }
        }, 5000);
        commit("SET_ENTITY", ["interval", intr]);
      }
      commit("SET_ENTITY", ["isFlight", false]);
      commit("SET_ENTITY", ["crewExist", false]);
    }

    commit("SET_ENTITY", ["flightLoading", false]);
  },
  async flightDetails({ commit }) {
    commit("SET_ENTITY", ["flightDetailsLoading", true]);
    try {
      let response = await this._vm.axios.get("/api/board/currentFlightDetail");
      commit("SET_ENTITY", ["flightDetails", response.data]);
      commit("SET_ENTITY", ["flightDetailsLoading", false]);
    } catch (e) {
      commit("SET_ENTITY", ["flightDetailsLoading", false]);
    }
  },
  async serverTime({ commit }) {
    let response = await this._vm.axios.get("/api/serverTime");
    commit("SET_ENTITY", ["serverTime", new Date(response.data.serverTime)]);
  },
  async serverName({ commit }) {
    commit("SET_ENTITY", ["serverNameLoading", true]);
    try {
      let response = await this._vm.axios.get("/api/board/serverName");
      commit("SET_ENTITY", ["serverName", response.data]);

      commit("SET_ENTITY", ["serverNameLoading", false]);
    } catch (e) {
      commit("SET_ENTITY", ["serverNameLoading", false]);
    }
  },
  async plane({ commit, rootState, state }) {
    commit("SET_ENTITY", ["planeLoading", true]);
    try {
      // let response = await this._vm.axios.get(`//api/board/aircraft-layouts/VPBIQ`);
      const response = await this._vm.axios.get(
        `/api/board/aircraft-layouts?nameHash=${rootState.user.user.nameHash}&seat=${rootState.user.user.seat}`
      );
      // const response = await this._vm.axios.get(
      //   `/api/board/aircraft-layouts/VQBPX`
      // );
      commit("SET_ENTITY", [
        "planeAirSeats",
        response.data.airSeats.filter(seat => seat.ageType !== "infant")
      ]);
      commit("SET_ENTITY", ["planeType", response.data.aircraftType]);

      const result = response.data.airSeats.filter(
        seat => !(seat.seatAvailability === 0 || seat.nameHash || seat.disabled)
      );
      commit("SET_ENTITY", ["planeLoading", false]);

      if (result.length) {
        try {
          if (state.intervalTariff) {
            clearInterval(state.intervalTariff);
          }
          await this._vm.axios.get(`/api/board/tariffs`);
          commit("SET_ENTITY", ["isUpgrade", true]);
        } catch (e) {
          commit("SET_ENTITY", ["isUpgrade", false]);
          const intr = setInterval(async () => {
            try {
              await this._vm.axios.get(`/api/board/tariffs`);
              commit("SET_ENTITY", ["isUpgrade", true]);
              if (state.intervalTariff) {
                clearInterval(state.intervalTariff);
              }
            } catch (e) {
              commit("SET_ENTITY", ["isUpgrade", false]);
            }
          }, 10000);
          commit("SET_ENTITY", ["intervalTariff", intr]);
        }
      }
    } catch (e) {
      commit("SET_ENTITY", ["planeLoading", "error"]);
    }
  },
  async updateSeat({ commit, dispatch }, payload) {
    try {
      // let response = await this._vm.axios.get(`/api/board/aircraft-layouts/${state.flightDetails.legs[0].aircraftNumber}`);
      await this._vm.axios.post(`/api/board/upgradePlace`, payload);
      commit(
        "user/SET_ENTITY",
        [
          "user",
          {
            firstName: payload.firstName,
            lastName: payload.lastName,
            seat: payload.upgrade
          }
        ],
        { root: true }
      );
      dispatch("plane");
    } catch (e) {
      commit("SET_ENTITY", ["planeLoading", "error"]);
    }
  }
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
};
