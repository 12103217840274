const initialState = () => ({
  index: [],
  indexLoading: "empty"
});

export const mutations = {
  SET_ENTITY(state, [key, payload]) {
    state[key] = payload;
  },
  RESET_STATE(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  }
};

export const actions = {
  async index({ commit }) {
    commit("SET_ENTITY", ["indexLoading", true]);
    const { data } = await this._vm.axios.get(`/api/banners`);
    commit("SET_ENTITY", ["index", data]);
    commit("SET_ENTITY", ["indexLoading", false]);
  }
};
export const getters = {};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  getters,
  actions
};
