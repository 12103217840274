const initialState = () => ({
  favorites: [],
  index: [],
  indexLoading: "empty",
  advertise: {},
  itemLoading: "empty",
  memory: []
});

export const mutations = {
  SET_ENTITY(state, [key, payload]) {
    state[key] = payload;
  },
  ADD_ENTITY(state, [key, payload, position = "start"]) {
    if (position === "end") {
      state[key].push(payload);
    } else {
      state[key].unshift(payload);
    }
  },
  UPDATE_ENTITY(state, [key, payload, payloadKey]) {
    const findIndex = state[key].findIndex(item => {
      return item[payloadKey] === payload[payloadKey];
    });
    if (findIndex) {
      state[key][findIndex] = payload;
    }
  },
  TOGGLE_ENTITY(state, [key, payload, payloadKey]) {
    const findIndex = state[key].findIndex(item => {
      return item[payloadKey] === payload[payloadKey];
    });
    if (findIndex !== -1) {
      state[key].splice(findIndex, 1);
    } else {
      state[key].push(payload);
    }
    state.indexLoading = "empty";
  },
  DELETE_ENTITY(state, [key, payload, payloadKey]) {
    const findIndex = state[key].findIndex(item => {
      return item[payloadKey] === payload[payloadKey];
    });
    if (findIndex !== -1) {
      state[key].splice(findIndex, 1);
    }
  },
  RESET_STATE(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  }
};

export const actions = {
  async toggleEntity({ commit, state, dispatch }, payload) {
    const findIndex = state.favorites.findIndex(item => {
      return item.id === payload.id;
    });

    if (findIndex !== -1) {
      commit("DELETE_ENTITY", ["favorites", payload, "id"]);
    } else {
      commit("ADD_ENTITY", ["favorites", payload, "id"]);
      const event = {};
      event.eventType = "AddToFavorites";
      event.contentId = payload.id;
      event.cdbId = payload.cdbId;
      event.position = payload.position || 0;
      dispatch("events/setEvent", event, { root: true });
    }
  },
  async index({ commit }, payload) {
    commit("SET_ENTITY", ["indexLoading", true]);
    const { data } = await this._vm.axios.post(`/api/favorites`, {
      favorites: payload.map(item => item.id)
    });
    commit("SET_ENTITY", ["index", data]);
    commit("SET_ENTITY", ["indexLoading", false]);
  }
};
export const getters = {
  CHECK_EXIST: state => content => {
    return state.favorites.find(item => item.id === content);
  },
  GET_AUDIO: state => {
    return state.favorites.filter(item => item.contentType === "Album");
  },
  GET_MOVIE: state => {
    return state.favorites.filter(item => item.contentType === "Movie");
  },
  GET_SERIAL: state => {
    return state.favorites.filter(item => item.contentType === "Serial");
  }
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  getters,
  actions
};
