const initialState = () => ({
  index: [],
  indexLoading: "empty",
  card: [],
  shopExist: false,
  shopPaidInfo: {},
  // shopPaidCode:"",
  shopPaidSuccess: false,
  isHaveAlcohol: false,
  isHave18:false
});

export const mutations = {
  SET_ENTITY(state, [key, payload]) {
    state[key] = payload;
  },
  RESET_STATE(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  },
  ADD_TO_CARD(state, payload) {
    const findIndex = state.card.findIndex(item => {
      return item.productId === payload.productId;
    });
    if (findIndex === -1) {
      state.card.push({ productId: payload.productId, amount: 1, category: payload.category });
    }
  },
  INCREMENT_AMOUNT_CARD(state, payload) {
    const findIndex = state.card.findIndex(item => {
      return item.productId === payload.productId;
    });
    if (findIndex === -1) {
      state.card.push({ productId: payload.productId, amount: 1 });
    } else {
      const item = state.index.find(it =>
        it.goods.find(
          i =>
            i.product_id === payload.productId &&
            i.volume > state.card[findIndex].amount
        )
      );
      if (item) {
        state.card[findIndex].amount++;
      }
    }
  },
  DECREMENT_AMOUNT_CARD(state, payload) {
    const findIndex = state.card.findIndex(item => {
      return item.productId === payload.productId;
    });
    if (findIndex !== -1) {
      state.card[findIndex].amount--;
      if (state.card[findIndex].amount <= 0) {
        state.card.splice(findIndex, 1);
      }
    }
  },
  DELETE_ENTITY(state, [key, payload, payloadKey]) {
    const findIndex = state[key].findIndex(item => {
      return item[payloadKey] === payload[payloadKey];
    });
    if (findIndex !== -1) {
      state[key].splice(findIndex, 1);
    }
  }
};

export const actions = {
  async index({ commit }) {
    commit("SET_ENTITY", ["indexLoading", true]);
    const { data } = await this._vm.axios.get("/api/pos/catalog", {
      headers: { Authorization: "3a156a2e-06f2-4512-81a5-4b43450305f4" }
    });
    let result = data;

    if (result) {
      result = result.filter(item => item.goods.find(it => it.volume));
      commit("SET_ENTITY", ["shopExist", result.length !== 0]);
      commit("SET_ENTITY", ["index", result]);
      commit("SET_ENTITY", ["indexLoading", false]);
    } else {
      commit("SET_ENTITY", ["shopExist", false]);
      commit("SET_ENTITY", ["index", []]);
      commit("SET_ENTITY", ["indexLoading", false]);
    }
  },
  changeAmount({ commit }, payload) {
    // if (!rootState.user.authenticate) {
    //   commit("user/SET_ENTITY", ["showModal", true], { root: true });
    // } else {
    commit(payload.action, payload);
    // }
  }
};
export const getters = {};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  getters,
  actions
};
