import muxjs from "mux.js";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "./plugins/resizer.js";
import "./plugins/Directives/ClickOutside.js";
import axios from "axios";
import VueAxios from "vue-axios";
import browserDetect from "vue-browser-detect-plugin";
import "./scss/s/main.scss";
import "./assets/styles/fixStyles.css";
window.muxjs = muxjs;
import titleMixin from "./mixins/titleMixin";
import animatedNumber from "@/components/plugins/AnimatedInteger";
import titleComponent from "@/components/MetaTitleComponent";
Vue.component("animated-number", animatedNumber);
Vue.component("meta-title", titleComponent);
Vue.mixin(titleMixin);
Vue.use(browserDetect);
Vue.use(VueAxios, axios);
Vue.config.productionTip = false;
import VueSocketIOExt from "vue-socket.io-extended";
import { io } from "socket.io-client";
import { uuid } from "vue-uuid";
let interval = setInterval(() => {
  if (
    !localStorage.getItem("uuid") ||
    (localStorage.getItem("uuid") && localStorage.getItem("uuid").length < 20)
  ) {
    localStorage.setItem("uuid", uuid.v1());
  }
  if (
    localStorage.getItem("uuid") &&
    localStorage.getItem("uuid").length > 20
  ) {
    clearInterval(interval);
  }
}, 100);

const socket = io(window.location.host);
Vue.use(VueSocketIOExt, socket);
axios.defaults.headers.common["Accept-Language"] = i18n.locale;
new Vue({
  sockets: {
    connect() {
      console.log("socket connected");
    }
  },
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
