const initialState = () => ({
  index: [],
  indexLoading: "empty",
  show: {},
  showLoading: false
});

export const mutations = {
  SET_ENTITY(state, [key, payload]) {
    state[key] = payload;
  },
  ADD_ENTITY(state, [key, payload, position = "start"]) {
    if (position === "end") {
      state[key].push(payload);
    } else {
      state[key].unshift(payload);
    }
  },
  UPDATE_ENTITY(state, [key, payload, payloadKey]) {
    const findIndex = state[key].findIndex(item => {
      return item[payloadKey] === payload[payloadKey];
    });
    if (findIndex) {
      state[key][findIndex] = payload;
    }
  },
  DELETE_ENTITY(state, [key, payload, payloadKey]) {
    const findIndex = state[key].findIndex(item => {
      return item[payloadKey] === payload[payloadKey];
    });
    if (findIndex !== -1) {
      state[key].splice(findIndex, 1);
    }
  },
  RESET_STATE(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  }
};

export const actions = {
  async index({ commit }, payload) {
    commit("SET_ENTITY", ["indexLoading", true]);
    const { data } = await this._vm.axios.get("/api/collections/" + payload);
    commit("SET_ENTITY", ["index", data]);
    commit("SET_ENTITY", ["indexLoading", false]);
  }
};
export const getters = {
  getSectionCollection: state => section => collection => {
    console.log(section, collection);
    return state.index;
  }
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  getters,
  actions
};
