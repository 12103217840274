import router from "@/router";
const initialState = () => ({
  user: {},
  authenticate: false,
  userLoading: "empty",
  skipCaptive: "empty",
  showModal: false,
  redirectAfterLogin: false,
  authError: false
});

export const mutations = {
  SET_ENTITY(state, [key, payload]) {
    state[key] = payload;
  },
  ADD_ENTITY(state, [key, payload, position = "start"]) {
    if (position === "end") {
      state[key].push(payload);
    } else {
      state[key].unshift(payload);
    }
  },
  UPDATE_ENTITY(state, [key, payload, payloadKey]) {
    const findIndex = state[key].findIndex(item => {
      return item[payloadKey] === payload[payloadKey];
    });
    if (findIndex) {
      state[key][findIndex] = payload;
    }
  },
  DELETE_ENTITY(state, [key, payload, payloadKey]) {
    const findIndex = state[key].findIndex(item => {
      return item[payloadKey] === payload[payloadKey];
    });
    if (findIndex !== -1) {
      state[key].splice(findIndex, 1);
    }
  },
  RESET_STATE(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  }
};

export const actions = {
  async loginUser({ commit, state, dispatch }, payload) {
    commit("SET_ENTITY", ["indexLoading", true]);
    commit("SET_ENTITY", ["authError", false]);
    let data = {};
    try {
      const response = await this._vm.axios.post("/api/pass/login", payload);
      data = response.data;
      if (data) {
        commit("SET_ENTITY", ["authenticate", true]);
        dispatch("order/index", {}, { root: true });
        dispatch("flight/foodMenu", {}, { root: true });
        dispatch("flight/services", {}, { root: true });
        dispatch("order/indexPurchasedContent", {}, { root: true });
        dispatch("order/updateOrderAfterLogin", {}, { root: true });
      } else {
        commit("SET_ENTITY", ["authenticate", false]);
      }
    } catch (e) {
      if (e.response.status === 401) {
        commit("SET_ENTITY", ["authError", true]);
      } else {
        commit("SET_ENTITY", ["authError", false]);
      }
      commit("SET_ENTITY", ["authenticate", false]);
    }
    if (Object.keys(data).length !== 0) {
      if (data.salon) {
        if (data.salon.toUpperCase() === "J") {
          data.class = "ss";
        } else if (data.salon.toUpperCase() === "W") {
          data.class = "c";
        } else {
          data.class = "e";
        }
      }
      commit("SET_ENTITY", ["user", data]);
      dispatch("flight/plane", {}, { root: true });
      commit("SET_ENTITY", ["showModal", false]);
    }
    commit("SET_ENTITY", ["userLoading", false]);
    commit("SET_ENTITY", ["skipCaptive", false]);
    commit("order/SET_ENTITY", ["indexLoading", "empty"], { root: true });

    if (state.redirectAfterLogin && data) {
      await router.push(state.redirectAfterLogin);

      commit("SET_ENTITY", ["redirectAfterLogin", false]);
    }
  }
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions
};
