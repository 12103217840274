<template>
  <div id="console " class="resize-observer"></div>
</template>

<script>
export default {
  mounted() {
    this.$nextTick(() => {
      this._w = this.$el.offsetWidth;
      this._h = this.$el.offsetHeight;
    });
    const object = document.createElement("object");
    this._resizeObject = object;
    object.setAttribute("aria-hidden", "true");
    object.setAttribute("tabindex", -1);
    object.onload = this.addResizeHandlers;
    object.type = "text/html";
    object.data = "about:blank";
    this.$el.appendChild(object);
  },
  beforeDestroy() {
    this.removeResizeHandlers();
  },
  methods: {
    compareAndNotify() {
      if (
        this._w !== this.$el.offsetWidth ||
        this._h !== this.$el.offsetHeight
      ) {
        this._w = this.$el.offsetWidth;
        this._h = this.$el.offsetHeight;
        this.$emit("notify", {
          width: this._w,
          height: this._h
        });

        let element = document.querySelector(".section-captive .text-col h4");
        if (element) {
          let height = element.offsetHeight;
          document.documentElement.style.setProperty(
            "--text-header-height",
            `${height}px`
          );
        }

        document.documentElement.style.setProperty(
          "--vh",
          `${window.innerHeight / 100}px`
        );
      }
    },

    resizeBg() {
      let feedback = document.querySelector(".section-feedback");

      if (feedback) {
        let width = parseInt(
          window.innerWidth && document.documentElement.clientWidth
            ? Math.min(window.innerWidth, document.documentElement.clientWidth)
            : window.innerWidth ||
                document.documentElement.clientWidth ||
                document.getElementsByTagName("body")[0].clientWidth,
          10
        );

        let height = parseInt(window.innerHeight, 10);
        console.log(width / height);
        console.log(width / height >= 1.5);
        if (width / height >= 1.5) {
          feedback.classList.add("landscape");
          feedback.classList.remove("portrait");
        } else {
          feedback.classList.add("portrait");
          feedback.classList.remove("landscape");
        }
      }
    },
    addResizeHandlers() {
      this._resizeObject.contentDocument.defaultView.addEventListener(
        "resize",
        this.compareAndNotify
      );
      this._resizeObject.contentDocument.defaultView.addEventListener(
        "resize",
        this.resizeBg
      );
      this._resizeObject.contentDocument.defaultView.addEventListener(
        "orientationchange",
        this.compareAndNotify
      );

      this.compareAndNotify();
    },
    removeResizeHandlers() {
      if (this._resizeObject && this._resizeObject.onload) {
        if (this._resizeObject.contentDocument) {
          this._resizeObject.contentDocument.defaultView.removeEventListener(
            "resize",
            this.compareAndNotify
          );
        }
        this.$el.removeChild(this._resizeObject);
        this._resizeObject.onload = null;
        this._resizeObject = null;
      }
    }
  }
};
</script>
<style scoped>
.resize-observer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  pointer-events: none;
  display: block;
  overflow: hidden;
  opacity: 0;
}
.resize-observer >>> object {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}
</style>
